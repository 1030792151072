<template>
	<div>
		<!-- <img :src="(src + wmPingzhao + wmStuname + wmDate + wmCard)" /> -->
	</div>
</template>

<script>
let base64 = require("./base64.min.js")
export default {

	data() {
		return {
			src: "https://xztoubao.oss-cn-hangzhou.aliyuncs.com/img/pingzheng.jpg?x-oss-process=image/",
			wmPingzhao: "watermark,text_${stu_name},g_nw,size_20,x_180,y_155",
			wmDate: "/watermark,text_MjAyMi4wMS4wMSAtIDIwMjMuMDEuMDEg,g_nw,size_20,x_780,y_155",
			wmStuname: "/watermark,text_572X54G_54G_,g_nw,size_20,x_200,y_193",
			wmCard: "/watermark,text_NDExNDU2MTk5OTAxMDIwMTIz,g_nw,size_20,x_780,y_193",
		};
	},
	mounted() {
		let str = base64.encode("XPX22120712777238");
		str = (str.replace(/\+/g,'-').replace(/\//g,'_').replace(/=/g,''))
		this.wmPingzhao =this.wmPingzhao.replace("${stu_name}",str) 
		

	},
	methods: {
		

	}
}


</script>

<style scoped="scoped">
.headerbox {
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 100%;

	padding: 20px 1px;
}

.headitem {
	border: 0px solid #eee;
	box-shadow: 0px 2px 3px 1px rgba(0, 0, 0, 0.1);
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 20px 60px 20px 30px;
	background-color: #fff;
	border-radius: 2px;
	cursor: pointer;
	text-decoration: none;
	box-sizing: border-box;
}

.headitem img {
	width: 40px;
	height: 40px;
}

.headitem span {
	padding-left: 20px;
	font-size: 18px;
}

.rowitem {
	display: flex;
	font-size: 14px;
	line-height: 30px;
	cursor: pointer;
	border-bottom: 1px solid #eee;
}

.rowtitle {
	width: 80%;
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
}

.rowdate {
	width: 20%;
	text-align: center;
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
}
</style>
